import React from "react";

export const IconBehance = ({ width, height }) => (
  <svg className="icon-svg" width={width} height={height} id="Group_978" data-name="Group 978" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.408 15.487">
    <path id="Path_607" data-name="Path 607" className="cls-1" d="M9.863,6.934a2.641,2.641,0,0,0,2.331-2.869C12.194,1.375,10.281,0,7.89,0H0V15.183H7.89s4.842.179,4.842-4.483C12.732,10.7,12.912,6.934,9.863,6.934ZM3.467,2.69H7.89s1.076,0,1.076,1.554S8.309,6.037,7.592,6.037H3.467Zm4.244,9.8H3.527V8.428H7.95s1.614,0,1.614,2.092A1.816,1.816,0,0,1,7.711,12.493Z" />
    <rect id="Rectangle_200" data-name="Rectangle 200" className="cls-1" width="6.276" height="1.853" transform="translate(15.9 0.897)" />
    <path id="Path_608" data-name="Path 608" className="cls-1" d="M28.094,6.5c-5.858,0-5.8,5.8-5.8,5.8a5.359,5.359,0,0,0,5.8,5.8s5.2.3,5.2-4H30.6s.06,1.614-2.451,1.614a2.477,2.477,0,0,1-2.69-2.63h7.831C33.355,13.075,34.251,6.5,28.094,6.5Zm-2.63,4.543a2.687,2.687,0,0,1,2.69-2.331,2.283,2.283,0,0,1,2.331,2.331Z" transform="translate(-8.966 -2.615)" />
  </svg>
);
export const IconDribbble = ({ width, height }) => (
  <svg className="icon-svg dribbble" width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.146 25.136">
    <path id="Path_4908" data-name="Path 4908" className="cls-1" d="M12.1-.012A12.068,12.068,0,1,1-.015,11.8,12.044,12.044,0,0,1,12.1-.012Zm2.148,13A13.688,13.688,0,0,0,8.39,16.2a14.468,14.468,0,0,0-3.357,4.4.334.334,0,0,0,.121.5A11.315,11.315,0,0,0,16.4,22.578a.271.271,0,0,0,.208-.342,37.789,37.789,0,0,0-1.4-6.445C14.909,14.858,14.573,13.938,14.244,12.985ZM4.356,20.416a15.483,15.483,0,0,1,3.961-5.075,14.657,14.657,0,0,1,5.666-3.008c-.389-.853-.765-1.658-1.128-2.471-.087-.195-.195-.148-.349-.107-.765.215-1.524.436-2.3.618A50.741,50.741,0,0,1,1.093,11.38c-.316.013-.423.094-.423.423A11.408,11.408,0,0,0,2.375,18.02,10.942,10.942,0,0,0,4.356,20.416ZM.771,10.709A43.317,43.317,0,0,0,12.4,9.064a.508.508,0,0,0-.013-.107A41.378,41.378,0,0,0,7.712,1.814c-.148-.181-.275-.161-.45-.074a11.379,11.379,0,0,0-5.888,6.4A9.538,9.538,0,0,0,.771,10.709ZM8.223,1.364A42.221,42.221,0,0,1,13.09,8.836,12.287,12.287,0,0,0,19.8,3.74,11.307,11.307,0,0,0,8.223,1.364ZM14.9,12.837a39.016,39.016,0,0,1,2.383,9.3c.081-.034.128-.04.161-.06A11.32,11.32,0,0,0,23.334,13.7c.034-.208-.013-.316-.242-.376a19.786,19.786,0,0,0-4.511-.779A13.532,13.532,0,0,0,14.9,12.837ZM20.32,4.224a13.085,13.085,0,0,1-6.9,5.23c.416.926.819,1.819,1.229,2.719a17.716,17.716,0,0,1,8.794.53A11.442,11.442,0,0,0,20.32,4.224Z" transform="translate(0.516 0.513)" />
  </svg>
  // <svg version="1.1" className="icon-svg" width={width} height={height}  xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
  // viewBox="0 0 21.3 21.3" style="enable-background:new 0 0 21.3 21.3;" >
  //   <path class="st0" d="M10.7,0C10.7,0,10.6,0,10.7,0C4.9,0,0.1,4.7,0,10.4l0,0.2c0,2.8,1.1,5.5,3.1,7.5c2,2,4.7,3.1,7.5,3.1
  //   c0,0,0,0,0,0c2.8,0,5.5-1.1,7.5-3.1c2-2,3.1-4.7,3.1-7.5C21.3,4.8,16.6,0,10.7,0z M5.1,18.1L5.1,18.1C5.1,18.1,5.1,18.1,5.1,18.1
  //   L5.1,18.1z M14.2,19.2C14.2,19.2,14.2,19.2,14.2,19.2C14.2,19.2,14.2,19.2,14.2,19.2C14.2,19.2,14.2,19.2,14.2,19.2z M12.4,8.7
  //   c2.1-0.8,4-2.2,5.4-4c1.4,1.7,2.2,3.8,2.2,6c-1.4-0.4-2.8-0.6-4.2-0.6c-0.9,0-1.8,0.1-2.6,0.2L12.4,8.7z M11.7,7.4
  //   c-1-2-2.3-3.9-3.7-5.7c3-0.9,6.2-0.2,8.6,1.9C15.5,5.4,13.7,6.7,11.7,7.4z M6.7,2.3C8.1,4,9.4,5.9,10.4,7.8C7.5,8.6,4.5,9,1.5,9.1
  //   C1.6,8.5,1.8,8,2,7.5C2.8,5.2,4.5,3.3,6.7,2.3z M9.2,9.6c0.5-0.1,1.1-0.3,1.6-0.4L11,9.1c0.2,0.4,0.4,0.8,0.6,1.2l0.1,0.3
  //   c-1.6,0.5-3.2,1.4-4.5,2.5c-1.3,1.1-2.4,2.4-3.2,4c-0.5-0.5-0.9-1-1.2-1.6c-0.9-1.5-1.4-3.2-1.4-5C4,10.4,6.6,10.1,9.2,9.6z
  //     M7.8,14.5c1.3-1.2,2.8-2,4.4-2.5l0.1,0.3c0.2,0.6,0.4,1.1,0.6,1.7c0.5,1.7,0.9,3.5,1.2,5.3c-3,1.2-6.4,0.8-9-1.2
  //   C5.7,16.7,6.7,15.5,7.8,14.5z M13.6,11.6c0.8-0.1,1.7-0.2,2.6-0.1c1.2,0.1,2.4,0.3,3.6,0.6c-0.4,2.7-2.1,5.1-4.4,6.5
  //   C15.1,16.2,14.5,13.9,13.6,11.6z"/>
  // </svg>


);
export const IconInstagram = ({ width, height }) => (
  <svg className="icon-svg" width={width} height={height} id="_x33_4hbmL.tif_1_" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.944 23.944">
    <g id="Group_976" data-name="Group 976">
      <path id="Path_602" data-name="Path 602" className="cls-1" d="M11.959,0c1.587.057,3.231.057,4.818.113a8.111,8.111,0,0,1,3.968,1.02,6.014,6.014,0,0,1,2.891,4.194,32.155,32.155,0,0,1,.283,3.287c.057,2.381,0,4.761,0,7.142a11.537,11.537,0,0,1-.453,3.684,6,6,0,0,1-4.818,4.194,21.454,21.454,0,0,1-3.287.283c-2.381.057-4.7,0-7.085,0a11.537,11.537,0,0,1-3.684-.453A6,6,0,0,1,.4,18.648,13.8,13.8,0,0,1,0,15.3V8.162A11.537,11.537,0,0,1,.453,4.478,6,6,0,0,1,5.271.283,21.454,21.454,0,0,1,8.559,0c1.19,0,2.267.057,3.4,0Zm9.806,11.789h0c-.057-.964-.057-1.87-.057-2.834a21.914,21.914,0,0,0-.17-2.721,4.052,4.052,0,0,0-3.4-3.8,14.968,14.968,0,0,0-3.174-.227c-2.04-.057-4.024-.057-6.065,0a17.435,17.435,0,0,0-3,.227,4,4,0,0,0-3.4,2.947,8.879,8.879,0,0,0-.283,1.7c-.057,2.154-.057,4.308-.057,6.462,0,1.36.057,2.777.17,4.138a3.952,3.952,0,0,0,3.457,3.8,19.469,19.469,0,0,0,3.174.227c1.984.057,3.968,0,6.008,0a21.914,21.914,0,0,0,2.721-.17,3.963,3.963,0,0,0,2.324-.907A4.35,4.35,0,0,0,21.6,17.174C21.708,15.417,21.708,13.6,21.765,11.789Z" transform="translate(0 0)" />
      <path id="Path_603" data-name="Path 603" className="cls-1" d="M22.543,16.421A6.122,6.122,0,1,1,16.478,10.3,6.1,6.1,0,0,1,22.543,16.421Zm-6.121,3.968a3.968,3.968,0,1,0-3.968-3.968A3.943,3.943,0,0,0,16.421,20.389Z" transform="translate(-4.462 -4.462)" />
      <path id="Path_604" data-name="Path 604" className="cls-1" d="M32.631,8.814a1.452,1.452,0,0,1-1.417,1.474,1.446,1.446,0,0,1,0-2.891A1.338,1.338,0,0,1,32.631,8.814Z" transform="translate(-12.907 -3.203)" />
    </g>
  </svg>
);

export const IconLinkedin = ({ width, height }) => (
  <svg className="icon-svg" width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.637 20.607">
    <g id="Group_2886" data-name="Group 2886" transform="translate(-37.59 -30.5)">
      <rect id="Rectangle_2584" data-name="Rectangle 2584" className="cls-1" width="4.798" height="13.729" transform="translate(37.593 37.379)" />
      <path id="Path_4976" data-name="Path 4976" className="cls-1" d="M39.992,30.5a2.406,2.406,0,0,0-2.341,2.948,2.431,2.431,0,0,0,1.85,1.821,2.387,2.387,0,0,0,2.89-2.341A2.418,2.418,0,0,0,39.992,30.5Z" transform="translate(0)" />
      <path id="Path_4977" data-name="Path 4977" className="cls-1" d="M76.764,58.52c-.318-2.6-1.619-4.22-5.116-4.22-2.486,0-3.468.376-4.046,1.474h0V54.3H63.7V68.029h3.989v-7.2c0-1.792.347-3.15,2.572-3.15,2.2,0,2.37,1.676,2.37,3.266v7.081h4.133S76.822,59.127,76.764,58.52Z" transform="translate(-18.564 -16.921)" />
    </g>
  </svg>
)

export const WaveLine = ({ width, height }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className="icon-waveline" width={width} height={height} viewBox="0 0 191.2 7.8">
    <defs>
      <style>
        {`.wave-1 {                
          stroke-width: 2px;
        }`}
      </style>
    </defs>
    <g id="XMLID_1_" transform="translate(1 1)">
      <path id="Path_4139" className="wave-1" data-name="Path 4139" d="M175.9,5.8a11.448,11.448,0,0,1-7.1-2.3,9.926,9.926,0,0,0-6.4-2.1A10.143,10.143,0,0,0,156,3.5a11.686,11.686,0,0,1-7.1,2.3,11.686,11.686,0,0,1-7.1-2.3,9.926,9.926,0,0,0-6.4-2.1A10.143,10.143,0,0,0,129,3.5a11.686,11.686,0,0,1-7.1,2.3,11.448,11.448,0,0,1-7.1-2.3,10.8,10.8,0,0,0-12.8,0,11.686,11.686,0,0,1-7.1,2.3,11.448,11.448,0,0,1-7.1-2.3A10.8,10.8,0,0,0,75,3.5a11.686,11.686,0,0,1-7.1,2.3,11.448,11.448,0,0,1-7.1-2.3,9.926,9.926,0,0,0-6.4-2.1A10.143,10.143,0,0,0,48,3.5a11.686,11.686,0,0,1-7.1,2.3,11.448,11.448,0,0,1-7.1-2.3,10.143,10.143,0,0,0-6.4-2.1A10.143,10.143,0,0,0,21,3.5a11.686,11.686,0,0,1-7.1,2.3A13.063,13.063,0,0,1,6.4,3.5,10.143,10.143,0,0,0,0,1.4V0A11.448,11.448,0,0,1,7.1,2.3a10.143,10.143,0,0,0,6.4,2.1,10.143,10.143,0,0,0,6.4-2.1A12.022,12.022,0,0,1,27.1,0a11.448,11.448,0,0,1,7.1,2.3,10.143,10.143,0,0,0,6.4,2.1A10.143,10.143,0,0,0,47,2.3a12.109,12.109,0,0,1,14.2,0,9.926,9.926,0,0,0,6.4,2.1A10.143,10.143,0,0,0,74,2.3,12.022,12.022,0,0,1,81.2,0a11.448,11.448,0,0,1,7.1,2.3,10.143,10.143,0,0,0,6.4,2.1,10.143,10.143,0,0,0,6.4-2.1A11.686,11.686,0,0,1,108.2,0a11.448,11.448,0,0,1,7.1,2.3,10.143,10.143,0,0,0,6.4,2.1,10.143,10.143,0,0,0,6.4-2.1A11.686,11.686,0,0,1,135.2,0a11.448,11.448,0,0,1,7.1,2.3,9.926,9.926,0,0,0,6.4,2.1,10.143,10.143,0,0,0,6.4-2.1A11.686,11.686,0,0,1,162.2,0a11.448,11.448,0,0,1,7.1,2.3,9.926,9.926,0,0,0,6.4,2.1,10.143,10.143,0,0,0,6.4-2.1A11.686,11.686,0,0,1,189.2,0V1.4a10.143,10.143,0,0,0-6.4,2.1A10.368,10.368,0,0,1,175.9,5.8Z" />
    </g>
  </svg>
)

export const ArrowBack = ({ width, height }) => (
  <svg className="icon-svg" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 29.763 18.85">
    <g id="Group_2163" data-name="Group 2163" transform="translate(29.763 18.85) rotate(180)">
      <path id="Path_4710" data-name="Path 4710" className="cls-1" d="M49.763,992.785a1.153,1.153,0,0,0-.28-.682l-7.937-8.433a.994.994,0,0,0-1.4-.039,1.022,1.022,0,0,0-.039,1.4l6.371,6.759H20.992a.992.992,0,0,0,0,1.984H46.476l-6.371,6.759a1.039,1.039,0,0,0,.039,1.4.993.993,0,0,0,1.4-.039l7.937-8.433a.882.882,0,0,0,.28-.682Z" transform="translate(-20 -983.36)" />
    </g>
  </svg>
)